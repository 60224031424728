import React, { useContext } from "react";
import { Button } from "antd";
import { AppContext } from "../../App";
import { Row, Col, Tag, Badge, Select } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckSquareFilled,
} from "@ant-design/icons";

const ListWells = () => {
  const { state, dispatch } = useContext(AppContext);

  return (
    <Row style={{ marginTop: "0px" }} align={"middle"} justify={"start"}>
      <Col>
        <Tag
          color="geekblue-inverse"
          style={{ marginBottom: "5px", fontSize: "13px" }}
        >
          Selecciona un punto de captación (1)
        </Tag>
        <br />
        <Select
          style={{ width: "300px", zIndex: 10000, color: "black" }}
          placeholder="Selecciona un punto de captación"
        >
          <Select.Option value={"1"}>Pozo 1</Select.Option>
        </Select>
      </Col>
    </Row>
  );
};

export default ListWells;
