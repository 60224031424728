import React, { useState, useEffect, useContext } from "react";
import sh from "../../api/sh/endpoints";
import Stats24Hours from "./Stats24ours";
import StatsMonth from "./StatsMonth";
import { Line } from "@ant-design/plots";
import { AppContext } from "../../App";

const GraphicLine = ({ option, initialDate, endDate, id_profile }) => {
  const [data, setData] = useState([
    {
      type: "caudal (lt/s)",
      value: "32.4",
      date: "00:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "89941",
      date: "00:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "16.6",
      date: "00:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "39.0",
      date: "01:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "89953",
      date: "01:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "14.7",
      date: "01:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "01:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "38.5",
      date: "02:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "89963",
      date: "02:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "12.7",
      date: "02:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 10,
      date: "02:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "33.4",
      date: "03:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "89975",
      date: "03:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "12.4",
      date: "03:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "03:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "31.1",
      date: "04:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "89987",
      date: "04:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "18.6",
      date: "04:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "04:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "31.0",
      date: "05:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "89999",
      date: "05:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "18.5",
      date: "05:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "05:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "33.2",
      date: "06:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90011",
      date: "06:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "17.3",
      date: "06:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "06:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "30.5",
      date: "07:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90023",
      date: "07:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "17.3",
      date: "07:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "07:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "28.9",
      date: "08:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90035",
      date: "08:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "17.4",
      date: "08:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "08:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "36.8",
      date: "09:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90047",
      date: "09:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "12.3",
      date: "09:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "09:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "30.1",
      date: "10:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90061",
      date: "10:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "16.9",
      date: "10:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 14,
      date: "10:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "30.6",
      date: "11:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90073",
      date: "11:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "14.1",
      date: "11:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "11:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "32.1",
      date: "12:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90085",
      date: "12:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "15.7",
      date: "12:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "12:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "33.3",
      date: "13:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90097",
      date: "13:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "15.2",
      date: "13:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "13:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "33.0",
      date: "14:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90109",
      date: "14:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "15.3",
      date: "14:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "14:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "31.9",
      date: "15:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90120",
      date: "15:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "15.4",
      date: "15:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 11,
      date: "15:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "32.0",
      date: "16:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90132",
      date: "16:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "14.9",
      date: "16:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "16:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "32.2",
      date: "17:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90144",
      date: "17:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "15.4",
      date: "17:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "17:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "31.5",
      date: "18:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90155",
      date: "18:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "17.6",
      date: "18:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 11,
      date: "18:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "29.2",
      date: "19:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90167",
      date: "19:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "13.5",
      date: "19:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "19:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "37.1",
      date: "20:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90179",
      date: "20:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "11.1",
      date: "20:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "20:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "30.5",
      date: "21:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90191",
      date: "21:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "15.2",
      date: "21:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "21:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "30.8",
      date: "22:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90203",
      date: "22:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "14.9",
      date: "22:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 12,
      date: "22:00 hrs",
    },
    {
      type: "caudal (lt/s)",
      value: "35.2",
      date: "23:00 hrs",
    },
    {
      type: "acumulado (m³)",
      value: "90214",
      date: "23:00 hrs",
    },
    {
      type: "nivel (m)",
      value: "10.4",
      date: "23:00 hrs",
    },
    {
      type: "acumulado(m³/hora)",
      value: 11,
      date: "23:00 hrs",
    },
  ]);
  const { state } = useContext(AppContext);

  const config = {
    data,
    xField: "date",

    xAxis: {
      title: {
        text: "2023-11-21",
        style: {
          fontSize: 14,
        },
      },
      tickInterval: 1,
    },
    yField: "value",
    yAxis: {
      tickInterval: 2,
      minValue: 0,
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s}.`),
      },
    },
    seriesField: "type",
    color: ["#5b8c00", "#002c8c", "#cf1322", "#262626"],
  };

  const configMonth = {
    data,
    xField: "date",

    xAxis: {
      title: {
        text: "2023-11-21",
        style: {
          fontSize: 14,
        },
      },
      tickInterval: 1,
    },
    yField: "value",
    yAxis: {
      tickInterval: 2,
      minValue: 0,
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s}.`),
      },
    },
    seriesField: "type",
    color: ["#5b8c00", "#002c8c", "#cf1322", "#262626"],
  };

  return (
    <>
      {option === 1 ? (
        <>
          {data && (
            <>
              <Line {...config} />
              <Stats24Hours data={data} />
            </>
          )}
        </>
      ) : (
        <>
          <Line {...configMonth} />
          <Stats24Hours data={data} />
        </>
      )}
    </>
  );
};

export default GraphicLine;
