import React, { useContext } from "react";
import { AppContext } from "../../App";
import { Typography, Card, Tag } from "antd";
import { useLocation } from "react-router-dom";

const { Title } = Typography;

const SiderLeft = () => {
  const { state } = useContext(AppContext);
  let location = useLocation();
  console.log(location.pathname);

  return (
    <Card
      style={{
        backgroundColor: "#1F3461",
        borderRadius: "20px",
        paddingRight: "5px",
        minHeight: "85vh",
        paddingLeft: "5px",
      }}
    >
      <Title
        align="center"
        style={{ color: "white", marginTop: "-15px" }}
        level={3}
      >
        {" "}
        Pozo
      </Title>
      <Title
        align="center"
        style={{ color: "white", marginTop: "-10px", marginBottom: "60px" }}
        level={5}
      >
        <Tag color="geekblue-inverse">Estandar</Tag>
      </Title>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          marginLeft: "-24px",
          marginTop: "-20px",
          marginRight: "-24px",
          marginBottom: "-10px",
        }}
      >
        Caudal Otorgado por la DGA:
        <br />
        <b>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            50 L/s
          </Typography.Paragraph>
        </b>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          marginLeft: "-24px",
          marginRight: "-24px",
          marginBottom: "-10px",
        }}
      >
        SHAC:
        <br />
        <b>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            Nombre sector
          </Typography.Paragraph>
        </b>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          marginLeft: "-24px",
          marginRight: "-24px",
          marginBottom: "-10px",
        }}
      >
        Fecha inicio monitoreo(Ikolu):
        <br />
        <b>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            2023-02-01
          </Typography.Paragraph>
        </b>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          marginLeft: "-24px",
          marginRight: "-24px",
          marginBottom: "-10px",
        }}
      >
        Fecha firma acta de entrega:
        <br />
        <b>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            2023-01-01
          </Typography.Paragraph>
        </b>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          marginLeft: "-24px",
          marginRight: "-24px",
          marginBottom: "-10px",
        }}
      >
        Profundida del pozo:
        <br />
        <b>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            100 mtrs
          </Typography.Paragraph>
        </b>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          marginLeft: "-24px",
          marginRight: "-24px",
          marginBottom: "-10px",
        }}
      >
        Posicionamiento de bomba:
        <br />
        <b>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            90 mtrs
          </Typography.Paragraph>
        </b>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          marginLeft: "-24px",
          marginRight: "-24px",
          marginBottom: "-10px",
        }}
      >
        Posicionamiento de sensor (freatico):
        <br />
        <b>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            80 mtrs
          </Typography.Paragraph>
        </b>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          marginLeft: "-24px",
          marginRight: "-24px",
          marginBottom: "-10px",
        }}
      >
        Diámetro ducto de salida (bomba)
        <br />
        <b>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            4 pulg
          </Typography.Paragraph>
        </b>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          marginLeft: "-24px",
          marginRight: "-24px",
          marginBottom: "50px",
        }}
      >
        Diámetro flujometro
        <br />
        <b>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            4 pulg
          </Typography.Paragraph>
        </b>
      </div>
    </Card>
  );
};

export default SiderLeft;
