import React from "react";

import { Row, Col, Typography, Input, Button, Collapse } from "antd";
import { SendOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const Support = () => {
  return (
    <Row>
      <Col span={24}>
        <Title level={3}>Soporte</Title>
      </Col>
      <Col span={12}>
        <Title level={4}>Abrir Ticket #12345</Title>
        <Paragraph>
          Este mensaje será recepcionado por el area de soporte técnico, para
          mayor información contactarse a <a>soporte@smarthydro.cl</a>
        </Paragraph>
        <Input.TextArea rows={8} />
        <Paragraph style={{ color: "gray", marginTop: "10px" }}>
          Su Ticket será contestado en las próximas 48 horas(días habiles).
        </Paragraph>
        <Button icon={<SendOutlined />} type="primary">
          Envíar Ticket
        </Button>
      </Col>
      <Col span={12}>
        <Title level={3} style={{ marginLeft: "10px" }}>
          Preguntas Frecuentes
        </Title>
        <Collapse style={{ marginLeft: "20px" }}>
          <Collapse.Panel
            header={
              "¿Qué es el Software D.G.A. de Monitoreo de Extracciones Efectivas (MEE)?"
            }
          >
            <Paragraph>
              Corresponde a una plataforma informática a la que los titulares de
              derechos de aprovechamiento de aguas y/o las personas informantes
              que ellos designen, acceden mediante Clave Única del Registro
              Civil
            </Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              "¿Qué es el Software D.G.A. de Monitoreo de Extracciones Efectivas (MEE)?"
            }
          >
            <Paragraph>
              Corresponde a una plataforma informática a la que los titulares de
              derechos de aprovechamiento de aguas y/o las personas informantes
              que ellos designen, acceden mediante Clave Única del Registro
              Civil
            </Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              "¿Qué es el Sistema de Monitoreo de Extracciones Efectivas de Aguas (MEE)?"
            }
          >
            <Paragraph>
              Corresponde a un conjunto de normas y procedimientos que la
              D.G.A., por resolución fundada, establece en el marco de la
              implementación de la Ley N° 21.064 de 27 de enero de 2018 que
              modificó el Código de Aguas, para que los titulares de derechos de
              aprovechamiento midan las extracciones efectivas de aguas que
              realizan desde los puntos de captación así como sus niveles
              freáticos y transmitan la información generada a la D.G.A. Este
              Sistema cuenta con una herramienta informática denominada Software
              D.G.A. de Monitoreo de Extracciones Efectivas.
            </Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              "¿Quiénes se deben registrar e ingresar información técnica y legal a través del Software D.G.A. de Monitoreo de Extracciones Efectivas de Aguas?"
            }
          >
            <Paragraph>
              Todos los titulares de derechos de aprovechamiento de aguas que
              por Resolución D.G.A. se les haya ordenado el Monitoreo de
              Extracciones Efectivas y Control de Extracciones, incluidos los
              derechos de aprovechamiento reconocidos por elsólo ministerio de
              la ley
            </Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              "¿Cuáles son los formatos para transmitir los datos al Software D.G.A. de Monitoreo de Extracciones Efectivas?"
            }
          >
            <Paragraph>
              El Software D.G.A. de Monitoreo de Extracciones Efectivas está
              construido para recibir los datos de totalizador, caudal y nivel
              freático a través de archivo formato Excel y XML. Dichos formatos
              se pueden bajar en la página web D.G.A. (
              <a>
                https://dga.mop.gob.cl/controlExtracciones/Paginas/documentos.aspx
              </a>
              ), en la sección de Monitoreo de Extracciones Efectivas.
            </Paragraph>
            <Paragraph>
              También existe la posibilidad de subir datos de totalizador,
              caudal y nivel freático por Formulario (llenado a mano). El
              titular de derecho de aprovechamiento de aguas deberá emplear el
              tipo de transmisión que le haya sido ordenado.
            </Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              "¿Cómo puedo borrar mediciones ya registradas en el software?"
            }
          >
            <Paragraph>
              El procedimiento para solicitar el borrado de mediciones de una
              obra se encuentra disponible en el Manual de Usuario Monitoreo de
              Extracciones Efectivas, disponible en la página web de la
              Dirección General de Aguas.
            </Paragraph>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default Support;
