import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Table, Typography, Button, Card } from "antd";
import sh from "../../api/sh/endpoints";
import { FileImageOutlined } from "@ant-design/icons";
import { AppContext } from "../../App";
import qr from "../../assets/images/qr.png";

const { Title } = Typography;

const Dga = () => {
  const { state } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [countElements, setCountElements] = useState(24);
  const [page, setPage] = useState(1);

  return (
    <Row justify={"center"}>
      <Col span={24}>
        <Title level={2}>
          DGA <br />
          <span style={{ fontSize: "20px" }}>
            Datos enviados a DGA en las últimas 24 horas:{" "}
            <strong>({countElements})</strong>
          </span>
        </Title>
      </Col>
      <Col span={13}>
        <Table
          style={{ borderRadius: "20px" }}
          bordered
          pagination={{
            total: countElements,
            onChange: (page) => setPage(page),
          }}
          size="small"
          dataSource={[
            {
              fecha: "2023-01-01 01:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 02:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 03:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 04:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 05:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 06:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 07:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 08:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 09:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 10:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 11:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 12:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 13:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 14:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 15:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 16:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 17:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 18:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 19:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 20:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 21:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 22:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
            {
              fecha: "2023-01-01 23:00",
              caudal: 10,
              nivel: 5,
              acumulado: "1.000.000",
            },
          ]}
          columns={[
            { title: "Fecha", dataIndex: "fecha" },
            {
              title: "Caudal(lt)",
              dataIndex: "caudal",
              render: (flow) => (flow < 0 ? "0.0" : flow),
            },
            { title: "Acumulado(m³)", dataIndex: "acumulado" },
            { title: "Nivel Freático(m)", dataIndex: "nivel" },
          ]}
        />
      </Col>
      <Col span={11} style={{ marginTop: "-100px", paddingLeft: "10px" }}>
        <Title level={4} style={{ textAlign: "center" }}>
          Consumo máximo anual:
        </Title>
        <Title level={3} style={{ marginTop: "-10px", textAlign: "center" }}>
          100.000 m³ / 50.000 m³
        </Title>
        <Table
          bordered
          size="small"
          pagination={false}
          style={{ marginBottom: "20px" }}
          dataSource={[
            {
              name: "Alarma 1",
              email: "correo@dominio.cl",
              acumulado: "100.000",
            },
            {
              name: "Alarma 2",
              email: "correo@dominio.cl",
              acumulado: "100.000.000",
            },
          ]}
          columns={[
            { title: "Nombre alarma", dataIndex: "name" },
            { title: "Correo Responsable", dataIndex: "email" },
            { title: "	Acumulado(m³)", dataIndex: "acumulado" },
          ]}
        ></Table>
        <center>
          <img src={qr} style={{ width: "60%" }} />

          <Title level={4} style={{ marginTop: "0px" }}>
            OB-1234-21
          </Title>
        </center>
      </Col>
    </Row>
  );
};

export default Dga;
