import React from "react";
import { Row, Typography, Col, Table, Input, Button, Upload } from "antd";
import { CloudUploadOutlined, CloudDownloadOutlined } from "@ant-design/icons";
const { Title } = Typography;

const DriveInternal = () => {
  return (
    <Row>
      <Col span={24}>
        <Title level={3}>Documentación y Respaldo</Title>
      </Col>
      <Col span={12}>
        <Table
          size="small"
          bordered
          pagination={false}
          columns={[
            { title: "#", dataIndex: "num" },
            { title: "Nombre", dataIndex: "name" },
            {
              title: "Archivo",
              render: () => (
                <Button
                  type="primary"
                  shape="circle"
                  icon={<CloudUploadOutlined />}
                ></Button>
              ),
            },
          ]}
          dataSource={[
            { name: "DA DGA", num: 1 },
            { name: "Inscripción DA en CBR", num: 2 },
            { name: "Prueba de bombeo", num: 3 },
            { name: "Informe mantención pozo", num: 4 },
          ]}
          title={() => "Documentación Cliente"}
          footer={() => (
            <>
              <Input
                placeholder="Nombre archivo"
                style={{ marginBottom: "20px" }}
              />
              <input type="file" />
              <Button
                type="primary"
                size="small"
                style={{ marginLeft: "10px" }}
              >
                Subir
              </Button>
            </>
          )}
        ></Table>
      </Col>
      <Col span={12}>
        <Table
          size="small"
          pagination={false}
          bordered
          columns={[
            { title: "#", dataIndex: "num" },
            { title: "Nombre", dataIndex: "name" },
            {
              title: "Archivo",
              render: () => (
                <Button
                  type="primary"
                  shape="circle"
                  icon={<CloudDownloadOutlined />}
                ></Button>
              ),
            },
          ]}
          dataSource={[
            { name: "Ficha levantamiento", num: 1 },
            { name: "Cotización", num: 2 },
            { name: "OC", num: 3 },
            { name: "Comprobantes de pago", num: 4 },
            { name: "Contrato", num: 5 },
            { name: "Ficha técnica equipos MEE", num: 6 },
            { name: "Informe técnico", num: 7 },
            { name: "Garantías", num: 8 },
          ]}
          title={() => "Documentación Smart Hydro"}
        ></Table>
      </Col>
    </Row>
  );
};

export default DriveInternal;
