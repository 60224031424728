import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Tag, Statistic } from "antd";
import wall from "../../assets/images/walldga.png";

import { AppContext } from "../../App";

const { Title } = Typography;

const Indicators = () => {
  const { state } = useContext(AppContext);
  const [load, setLoad] = useState(false);
  const [ind1, setInd1] = useState(0);
  const [ind2, setInd2] = useState(0.0);

  return (
    <Row justify="center" style={{ padding: "0px" }}>
      <Col span={24} style={{ marginTop: "-20px" }}>
        <Row
          justify={"space-between"}
          align={"top"}
          style={{ marginTop: "20px" }}
        >
          <Col span={8} style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Row align="middle">
                <Col
                  span={24}
                  style={{
                    marginBottom: "10px",
                    color: "white",
                    fontWeight: "800",
                  }}
                >
                  Hora consumo (m³/hora)
                </Col>
                <Col span={10}>
                  <Tag color="green-inverse">Max</Tag>
                </Col>
                <Col span={14}>
                  <Tag color="green-inverse">03:00</Tag>
                  <br />
                  <Tag color="green-inverse"> 30 m³/hora</Tag>
                </Col>
                <Col
                  span={24}
                  style={{
                    borderBottom: "2px dashed white",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                ></Col>
                <Col span={10}>
                  <Tag color="volcano-inverse">Min</Tag>
                </Col>
                <Col span={14}>
                  <Tag color="volcano-inverse">06:00</Tag> <br />{" "}
                  <Tag color="volcano-inverse">220 m³/hora</Tag>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={8} style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Row align="middle">
                <Col
                  span={24}
                  style={{
                    marginBottom: "10px",
                    color: "white",
                    fontWeight: "800",
                  }}
                >
                  Caudal (lt/s)
                </Col>
                <Col span={10}>
                  <Tag color="green-inverse">Max</Tag>
                </Col>
                <Col span={14}>
                  <Tag color="green-inverse">06:00</Tag>
                  <br /> <Tag color="green-inverse">5,8 lt/s</Tag>
                </Col>
                <Col
                  span={24}
                  style={{
                    borderBottom: "2px dashed white",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                ></Col>
                <Col span={10}>
                  <Tag color="volcano-inverse">Min</Tag>
                </Col>
                <Col span={14}>
                  <Tag color="volcano-inverse">08:00</Tag> <br />{" "}
                  <Tag color="volcano-inverse">2,7 lt/s</Tag>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={8} style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Row align="middle">
                <Col
                  span={24}
                  style={{
                    marginBottom: "10px",
                    color: "white",
                    fontWeight: "800",
                  }}
                >
                  Nivel freático (m)
                </Col>
                <Col span={10}>
                  <Tag color="green-inverse">Max</Tag>
                </Col>
                <Col span={14}>
                  <Tag color="green-inverse">10:00</Tag>
                  <br /> <Tag color="green-inverse">8,0 m</Tag>
                </Col>
                <Col
                  span={24}
                  style={{
                    borderBottom: "2px dashed white",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                ></Col>
                <Col span={10}>
                  <Tag color="volcano-inverse">Min</Tag>
                </Col>
                <Col span={14}>
                  <Tag color="volcano-inverse">12:00</Tag> <br />{" "}
                  <Tag color="volcano-inverse">4,2 m</Tag>
                </Col>
              </Row>
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={<span style={{ color: "white" }}>m³</span>}
                valueStyle={{
                  color: "white",
                }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Consumo total (m³)
                    </Col>
                  </Row>
                }
                value={"230"}
              />
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            {" "}
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={<span style={{ color: "white" }}>m³/hora</span>}
                valueStyle={{ color: "white" }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Promedio consumo (m³/hora)
                    </Col>
                  </Row>
                }
                value={"25"}
              />
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={<span style={{ color: "white" }}>kw</span>}
                valueStyle={{ color: "white" }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Consumo energético de equipos
                    </Col>
                  </Row>
                }
                value={"300"}
              />
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={<span style={{ color: "white" }}>hrs</span>}
                valueStyle={{ color: "white" }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Horas de operación de los equipos
                    </Col>
                  </Row>
                }
                value={"500"}
              />
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={<span style={{ color: "white" }}>%</span>}
                valueStyle={{ color: "white" }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Eficiencia de equipos
                    </Col>
                  </Row>
                }
                value={"50"}
              />
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={<span style={{ color: "white" }}>%</span>}
                valueStyle={{ color: "white" }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Eficiencia de la captación
                    </Col>
                  </Row>
                }
                value={"83"}
              />
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={
                  <span style={{ color: "white", fontSize: "14px" }}>
                    (m3/día área)-(m3/día punto)
                  </span>
                }
                valueStyle={{ color: "white" }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Demanda de agua por sector
                    </Col>
                  </Row>
                }
                value={"100"}
              />
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={<span style={{ color: "white" }}>mg/l</span>}
                valueStyle={{ color: "white" }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Cloro libre residual
                    </Col>
                  </Row>
                }
                value={"<2"}
              />
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={<span style={{ color: "white" }}>mg/l</span>}
                valueStyle={{ color: "white" }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Hierro
                    </Col>
                  </Row>
                }
                value={"<0,3"}
              />
            </div>
          </Col>
          <Col style={{ marginBottom: "10px" }}>
            <div
              style={{
                background: `url(${wall})`,
                padding: "25px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                borderRadius: "20px",
                width: "240px",
              }}
            >
              <Statistic
                suffix={<span>%</span>}
                valueStyle={{ color: "white" }}
                title={
                  <Row>
                    <Col style={{ color: "white", fontWeight: "800" }}>
                      Perdidas en la red de extracción
                    </Col>
                  </Row>
                }
                value={"32"}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Indicators;
